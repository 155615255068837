<template>
  <div class="plan-tiers">
    <div
      class="block-icon-reglement-fac mr-1"
      @click.prevent.stop="handelModalInfo('show')"
      title="Plan tiers"
    >
      <font-awesome-icon icon="file-invoice-dollar" class="file-medical" />
    </div>
    <v-dialog
      v-model="planTierModal"
      persistent
      max-width="990px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add custom-vuetify-dialog-plan-tiers"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Gestion de plan tiers </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handelModalInfo('hide')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div v-if="isLoadingS">
            <v-progress-circular
              class="mt-4"
              style="margin-left:50%"
              v-if="isLoadingS"
              indeterminate
              color="#704ad1"
            ></v-progress-circular>
          </div>
          <template v-else>
            <v-row class="mt-2  justify-between">
              <b-col cols="4">
                <v-text-field
                  label="Rechercher"
                  v-model="searchValue"
                  outlined
                  clearable
                  hide-details
                  :persistent-placeholder="true"
                  :placeholder="'Rechercher'"
                  color="#704ad1"
                ></v-text-field
              ></b-col>
              <b-col cols="4"></b-col>
              <b-col cols="3">
                <infoTiers
                  :dataToUse="compteTier"
                  @validDataCompta="validDataCompta($event)"
                ></infoTiers> </b-col
              ><b-col cols="1">
                <div class="mt-0 pt-0 pl-6">
                  <v-tooltip bottom color="#311B92">
                    <template v-slot:activator="{ on, attrs }">
                      <v-file-input
                        v-on="on"
                        v-bind="attrs"
                        v-model="files"
                        label="File input"
                        hide-input
                        @change="handelUploadFileTier"
                        class="custom-input-file-icon"
                      ></v-file-input>
                    </template>
                    <span>Mise à jour de la plan tiers par fichier xls </span>
                  </v-tooltip>
                </div>
              </b-col>
            </v-row>
            <div v-if="isLoadingF">
              <v-progress-circular
                class="mt-4"
                style="margin-left:50%"
                v-if="isLoadingF"
                indeterminate
                color="#704ad1"
              ></v-progress-circular>
            </div>
            <v-row class="mt-2">
              <v-col cols="4"></v-col>
              <v-col cols="2">
                <div
                  class="block-icon-table-facture fix-width mr-1"
                  @click.prevent.stop="handelAddPlanTier()"
                  title="Ajouter un client"
                >
                  <font-awesome-icon icon="plus" /> Ajouter un client
                </div>
              </v-col>
              <v-col cols="4"> </v-col>
            </v-row>
            <v-form ref="formPlan" lazy-validation>
              <!-- CLIENT DATA  -->
              <div
                class="card-style pt-2"
                v-for="(plan, index) in computedPlanTiers"
                :key="'compta-tiers' + index"
              >
                <span class="title-card"
                  >Compte Tiers {{ computedClietnName(plan.client_id) }}</span
                >
                <v-row class="mt-0 justify-end h-15" v-if="plan.id">
                  <v-switch
                    v-model="plan.is_active"
                    :value="1"
                    :unchecked-value="0"
                    :label="'Activer'"
                    color="#704ad1"
                    class="mt-0"
                  >
                  </v-switch
                ></v-row>
                <v-row class="mt-0 justify-end" v-else>
                  <v-btn
                    icon
                    @click.prevent.stop="deleteCleint(plan)"
                    title="Supprimer"
                    color="#704ad1"
                  >
                    <font-awesome-icon icon="trash" class="fa-lg" />
                  </v-btn>
                </v-row>
                <!-- COMPNAY TO USE  -->
                <v-row class="mt-1">
                  <!-- SOCIETE -->
                  <v-col cols="4">
                    <v-autocomplete
                      :clearable="true"
                      v-model="plan.client_id"
                      :items="getFiliaeOfResponsableCopy"
                      item-text="name"
                      item-value="id"
                      color="#704ad1"
                      item-color="#704ad1"
                      class="mb-1 msg-error"
                      placeholder="Séléctionnez"
                      :loading="getFactureLibreLoadingTh"
                      :persistent-placeholder="true"
                      label="Client"
                      required
                      :rules="[v => !!v || 'Client obligatoire']"
                      validate-on-blur
                      dense
                      :disabled="plan.id ? true : false"
                      :auto-focus="false"
                      no-data-text="Aucun Client trouvé"
                      outlined
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                    >
                    </v-autocomplete
                  ></v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Compte client"
                      mess
                      :persistent-placeholder="true"
                      v-model="plan.client"
                      outlined
                      color="#704ad1"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Compte fournisseur"
                      mess
                      :persistent-placeholder="true"
                      v-model="plan.fournisseur"
                      outlined
                      color="#704ad1"
                    >
                    </v-text-field> </v-col
                ></v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      label="Compte reste à charges"
                      mess
                      :persistent-placeholder="true"
                      v-model="plan.reste_charge"
                      outlined
                      color="#704ad1"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Compte commission"
                      mess
                      :persistent-placeholder="true"
                      v-model="plan.commission"
                      outlined
                      color="#704ad1"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      label="Compte prime"
                      mess
                      :persistent-placeholder="true"
                      v-model="plan.prime"
                      outlined
                      color="#704ad1"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </template>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <div
            v-if="error"
            class="message-error-modal"
            style="margin-right: calc(45% - 220px );"
          >
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div class="message-error-modal" v-else>{{ error }}</div>
          </div>
          <div
            v-if="succesMessage"
            class="message-succes-modal"
            style="margin-right: 20px;"
          >
            {{ succesMessage }}
          </div>
          <v-btn
            color="#704ad1"
            outlined
            @click.prevent="handelValidData"
            :loading="isLoading"
            :disabled="isLoading || compteTier.length == 0"
            :class="{ loader: isLoading }"
          >
            Valider
          </v-btn>
          <v-btn text @click="handelModalInfo('hide')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: { item: { required: true } },
  data() {
    return {
      planTierModal: false,
      error: null,
      isLoading: false,
      isLoadingS: false,
      compteTier: [],
      files: null,
      isLoadingF: false,
      searchValue: null,
      succesMessage: null
    };
  },
  computed: {
    ...mapGetters([
      'getFiliaeOfResponsableCopy',
      'getPlanTiersLoader',
      'getFactureLibreLoadingTh'
    ]),
    computedClietnName() {
      return function(data) {
        if (data) {
          let item = this.getFiliaeOfResponsableCopy.filter(i => i.id == data);
          if (item.length > 0) {
            item = item[0].name;
          } else {
            item = '';
          }
          return item;
        }
      };
    },
    computedPlanTiers() {
      if (this.searchValue != null && this.searchValue != '') {
        let ids = this.getFiliaeOfResponsableCopy
          .filter(i =>
            i.name.toLowerCase().includes(this.searchValue.toLowerCase())
          )
          .map(i => i.id);
        return this.compteTier.filter(i => ids.includes(i.client_id));
      }
      return this.compteTier;
    }
  },
  methods: {
    ...mapActions([
      'getFilialsOfConnectedResponsableCoy',
      'getPlanTiersOfFiliale',
      'cudPlanTiersForSociete',
      'updatePlanTiersFilialeWithXlsFile'
    ]),
    async handelUploadFileTier() {
      this.error = null;
      if (this.files != null) {
        var bodyFormData = new FormData();
        bodyFormData.append('file', this.files);
        bodyFormData.append('filiale', this.item.id);
        this.isLoadingF = true;
        const response = await this.updatePlanTiersFilialeWithXlsFile(
          bodyFormData
        );
        if (response.succes) {
          this.compteTier = response.data;
        } else {
          this.error = response.error;
        }
        this.isLoadingF = false;
      }
    },
    validDataCompta(data) {
      this.compteTier = data;
    },
    deleteCleint(plan) {
      if (plan.indexObject) {
        this.compteTier = this.compteTier.filter(
          i =>
            !i.indexObject ||
            (i.indexObject && i.indexObject != plan.indexObject)
        );
      }
    },
    handelAddPlanTier() {
      this.compteTier.unshift({
        client_id: null,
        client: null,
        fournisseur: null,
        reste_charge: null,
        commission: null,
        prime: null,
        is_active: 1,
        indexObject: 'new' + this.compteTier.length
      });
    },
    async handelModalInfo(action) {
      if (action == 'show') {
        this.planTierModal = true;
        this.isLoadingS = true;
        this.getFilialsOfConnectedResponsableCoy({ minimum: 'yes' });
        const response = await this.getPlanTiersOfFiliale(this.item.id);
        if (response.succes) {
          this.compteTier = response.data;
        }
        this.isLoadingS = false;
      } else {
        this.planTierModal = false;
        this.resetModal();
      }
    },
    resetModal() {
      this.error = null;
      this.compteTier = [];
      this.succesMessage = null;
      this.$refs.formPlan.reset();
    },
    async handelValidData() {
      this.succesMessage = null;
      if (this.$refs.formPlan.validate()) {
        this.isLoading = true;
        const response = await this.cudPlanTiersForSociete({
          planTiers: this.compteTier,
          id: this.item.id
        });
        if (response.succes) {
          this.compteTier = response.data;
          this.succesMessage = 'Mise à jour de plan tiers avec succès';
          setTimeout(() => {
            this.succesMessage = null;
          }, 2500);
          // this.$alert(
          //   '',
          //   'Mise à jour de plan tiers avec succès',
          //   'success'
          // ).then(() => {
          //   this.planTierModal = true;
          // });
        }
        this.isLoading = false;
      }
    }
  },
  components: {
    infoTiers: () => import('./infoTiers')
  }
};
</script>

<style scoped lang="scss">
.block-icon-reglement-fac {
  padding: 0px;
  text-align-last: center;
  padding-top: 15%;
}
</style>
<style lang="scss">
.v-dialog.custom-vuetify-dialog-plan-tiers.v-dialog--active.v-dialog--persistent.v-dialog--scrollable {
  top: 30px !important;
  position: absolute !important;
}
.custom-vuetify-dialog-plan-tiers {
  .fix-width {
    width: 128px !important;
    padding-bottom: 23px;
    margin-left: 42%;
    padding-left: 8px;
    margin-top: 5%;
  }
  .card-style {
    border-radius: 10px;
    border: 1px solid #b5b5b5;
    padding: 28px;
    margin-top: 16px;
    margin-bottom: 20px;
    position: relative;

    .title-card {
      position: absolute;
      top: -12px;
      font-size: 12px;
      background-color: #fff;
      padding-left: 5px;
      color: rgb(0 0 0 / 41%);
    }
  }
}
.custom-input-file-icon {
  margin-top: 0px;
  padding-top: 0px;
  .v-input__prepend-outer {
    margin-top: 3px !important;
    .v-icon--link {
      color: #704ad1;
    }
  }
}
</style>
